import { Header } from '@almbrand/header';
import { Location, NavLink, useLocation } from 'react-router-dom';

export const HeaderComponent: React.FC<HeaderReference> = ({
	logoImage,
	logoUrl,
	headerLinks,
	loginUrl,
	loginLabelText,
	//	menuMobilIcon,
	//	menuCloseMobilIcon,
	//	claimsUrl,
	//	claimsLabelText,
	//	searchLabelText,
	//	searchIcon,
	privateLabel,
	privatePageUrl,
	businessLabel,
	businessPageUrl,
}) => {
	const location: Location = useLocation();
	const rootUrl = window.location.origin;

	const createSegmentLink = (label, pageUrl) => {
		const trackingDataJson = JSON.stringify({
			event: 'trackNavigation',
			navigationAction: label ?? '',
			navigationDestination: `${rootUrl}${pageUrl.url ?? ''}`,
			navigationType: 'top navigation',
		});

		return {
			text: label ?? '',
			url: pageUrl?.url ?? '',
			isExternalLink: pageUrl?.isExternalLink ?? false,
			useVueRoute: pageUrl?.useVueRoute ?? false,
			trackEvent: trackingDataJson,
		};
	};

	const mapHeaderLinks = (links) => {
		return links
			.map((link) => ({
				text: link?.text ?? '',
				title: link?.title ?? '',
				url: link?.url ?? '',
				target: link?.target ?? '',
				isExternalLink: link?.isExternalLink ?? false,
				useVueRoute: link?.useVueRoute ?? false,
				trackEvent: JSON.stringify({
					event: 'trackNavigation',
					navigationAction: link?.text ?? '',
					navigationDestination: `${rootUrl}${link?.url ?? ''}`,
					navigationType: 'primary navigation',
				}),
			}))
			.filter((link) => link.text && link.url);
	};

	const segmentLinks = [
		privateLabel && createSegmentLink(privateLabel, privatePageUrl),
		businessLabel && createSegmentLink(businessLabel, businessPageUrl),
	].filter(Boolean);

	return (
		<Header
			logoUrl={logoImage?.url ?? ''}
			logoLink={logoUrl?.url ?? ''}
			segmentLinks={segmentLinks}
			navigationLinks={mapHeaderLinks(headerLinks)}
			buttonProps={{
				buttonType: 'personal',
				children: loginLabelText,
				size: 'small',
				linkButtonSettings: {
					href: loginUrl?.url,
				},
				iconProps: {
					themeName: 'Codan-theme',
					icon: 'lock',
					width: '36',
					height: '36',
				},
			}}
			renderChildren={(link: Link): JSX.Element => {
				return link.text === 'Privat' ? (
					<NavLink
						className={() => !location.pathname.includes('erhverv') && 'active'}
						key={link.url}
						to={link.url}
						title={link.title}
						target={link.target}
						data-track-event={link.trackEvent}
					>
						{link.text}
					</NavLink>
				) : (
					<NavLink
						key={link.url}
						to={link.url}
						title={link.title}
						target={link.target}
						data-track-event={link.trackEvent}
					>
						{link.text}
					</NavLink>
				);
			}}
		/>
	);
};
