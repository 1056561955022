import { useEffect, useState } from 'react';

/**
 * Custom hook to determine the scroll direction of the window.
 *
 * @param {React.RefObject<HTMLElement>} headerRef - A reference to the header element.
 * @param {number} [threshold=0] - The minimum scroll difference to detect a change in direction.
 * @returns {string} - The current scroll direction, either 'UP' or 'DOWN'.
 */
export const useScrollDirection = (headerRef, threshold = 0) => {
	const [lastScrollTop, setLastScrollTop] = useState(0);
	const [scrollDirection, setScrollDirection] = useState('UP');

	useEffect(() => {
		/**
		 * Updates the scroll direction based on the current scroll position.
		 */
		const updateScrollDirection = () => {
			const { scrollTop } = document.documentElement;
			const currentScrollTop = window.scrollY || scrollTop;
			const verticalScrollDifference = Math.abs(currentScrollTop - lastScrollTop);

			if (currentScrollTop === 0) {
				setScrollDirection('UP');
			}

			if (verticalScrollDifference <= threshold) return;

			const headerElement = headerRef.current;

			if (headerElement && currentScrollTop > lastScrollTop) {
				/*
                    `rect` represents the bounding rectangle around the DOM element
                */
				const headerRect = headerElement.getBoundingClientRect();

				const isHeaderInView = headerRect.bottom <= window.innerHeight;

				isHeaderInView && setScrollDirection('DOWN');
			} else if (currentScrollTop < lastScrollTop) {
				setScrollDirection('UP');
			}

			setLastScrollTop(currentScrollTop);
		};

		window.addEventListener('scroll', updateScrollDirection);
		return () => window.removeEventListener('scroll', updateScrollDirection);
	}, [headerRef, lastScrollTop, threshold]);

	return scrollDirection;
};
