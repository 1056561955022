import { GetFeedback } from '@almbrand/getfeedback';

export interface GetFeedbackComponentProps {
	usabillaId: string;
	usabillaCategory?: string;
	subCategory?: string;
}

export const GetFeedbackComponent: React.FC<GetFeedbackComponentProps> = ({
	usabillaId,
	usabillaCategory,
	subCategory,
}) => {
	return <GetFeedback getFeedbackId={usabillaId} category={usabillaCategory} subCategory={subCategory} />;
};
