import { PageContext } from 'context/PageContext';
import React, { useContext, useEffect, useRef } from 'react';

import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import classNames from 'classnames';
import { ChatbotComponent, NotificationBanner } from 'components/2-molecules';
import { ContainerLayout, FooterComponent, HeaderComponent } from 'components/4-habitats';

import { LocalNavigationTopPositionContext } from 'context/LocalNavigationTopPositionContext';
import { useScrollDirection } from 'hooks/useScrollDirection';
import { useTrackPage } from 'hooks/useTrackPage';

export const ContentPage: React.FC<PageModel> = () => {
	const pageContext = useContext(PageContext);
	const { topValue } = useContext(LocalNavigationTopPositionContext);

	const { headerContent, content, footerContent, notificationBannerContent, ChatbotContent } = pageContext ?? {};

	const headerRef = useRef<HTMLDivElement>(null);
	const scrollDirection = useScrollDirection(headerRef, 100);

	useTrackPage();

	useEffect(() => {
		// Handle page load with hash
		const hash = window.location.hash;
		if (hash) {
			const element = document.getElementById(hash.substring(1));
			if (element) {
				element.scrollIntoView();
			}
		}
	}, []);

	return (
		<>
			<Container
				desktopWidth="fullWidth"
				tabletWidth="fullWidth"
				mobileWidth="fullWidth"
				className={classNames(
					'Header',
					scrollDirection == 'UP' && topValue > 100 ? 'Header_sticky' : 'Header_hidden',
				)}
			>
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
					<Grid ref={headerRef}>
						<GridCell>
							<HeaderComponent {...headerContent} />
						</GridCell>
					</Grid>
				</Container>
			</Container>

			{notificationBannerContent && (
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
					<Grid container>
						<GridCell>
							<NotificationBanner {...notificationBannerContent} />
						</GridCell>
					</Grid>
				</Container>
			)}

			{content?.items?.map((component) => (
				<ContainerLayout component={component} key={component?.content?.contentId} />
			))}

			<Container noPadding desktopWidth="fullWidth" tabletWidth="fullWidth" mobileWidth="fullWidth">
				<FooterComponent {...footerContent} />
				{!ChatbotContent && <ChatbotComponent />}
			</Container>
		</>
	);
};
