import { ContentPage } from 'components';
import { LocalNavigationTopPositionProvider } from 'context/LocalNavigationTopPositionContext';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

export const pages = {
	FrontPage: ContentPage,
	ContentPage: ContentPage,
};

export const PageType: React.FC<PageModel> = () => {
	const pageContext = useContext(PageContext);
	const pageType = pageContext.pageType;

	if (typeof pages[pageType] === 'undefined') {
		return <div>{`Page type [${pageType}] is missing a React Component. Check /components/Page.tsx`}</div>;
	}

	const DynamicPageTemplate = pages[pageType];

	return (
		<LocalNavigationTopPositionProvider>
			<DynamicPageTemplate />
		</LocalNavigationTopPositionProvider>
	);
};
