import { Chatbot } from '@almbrand/chatbot';
import './ChatbotComponent.scss';

export const ChatbotComponent: React.FC = () => {
	return (
		<Chatbot
			chatbotApiUrl={'https://codanekstern.boost.ai/api'}
			icon={{
				iconProp: { themeName: 'Codan-theme', icon: 'chatbot-trigger' },
			}}
			storybook={false}
		/>
	);
};
