import { createContext, useMemo, useState } from 'react';

interface LocalNavigationContextType {
	topValue: number;
	setTopValue: (value: number) => void;
}

export const LocalNavigationTopPositionContext = createContext<LocalNavigationContextType | undefined>(undefined);

export const LocalNavigationTopPositionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [topValue, setTopValue] = useState<number>(1000);
	const contextValue = useMemo(() => ({ topValue, setTopValue }), [topValue]);

	return (
		<LocalNavigationTopPositionContext.Provider value={contextValue}>
			{children}
		</LocalNavigationTopPositionContext.Provider>
	);
};
