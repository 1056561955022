import {
	DividerComponent,
	GetFeedbackComponent,
	HeadlineComponent,
	MediaComponent,
	ParagraphComponent,
	YoutubeComponent,
} from './1-atoms';
import { BentoComponent, IframeComponent, TileComponent } from './2-molecules';
import { AccordionComponent, HeroComponent, TeaserCard } from './3-organisms';
import { LocalNavigation } from './4-habitats';
import FormRenderer from './4-habitats/FormRenderer/FormRenderer';

export const Component: React.FC<ContainerBlock> = (props) => {
	const components = {
		TileBlock: TileComponent,
		ParagraphBlock: ParagraphComponent,
		HeadLineBlock: HeadlineComponent,
		DividerBlock: DividerComponent,
		HeroBlock: HeroComponent,
		TeaserCardBlock: TeaserCard,
		BatgirlIFrameBlock: IframeComponent,
		ReadlineBlock: LocalNavigation,
		AccordionContainerBlock: AccordionComponent,
		MediaBlock: MediaComponent,
		YoutubeBlock: YoutubeComponent,
		GlobalFormContainerBlock: FormRenderer,
		BentoBlock: BentoComponent,
		UsabillaBlock: GetFeedbackComponent,
	};

	// Check if the contentType is recognized or should use a default handling
	const DynamicComponent = components[props.contentType];

	if (!DynamicComponent) {
		if (props.contentType === 'DefaultContainerBlock') {
			// Handle the DefaultContainerBlock differently or do nothing
			return null; // Do nothing, or render a default placeholder as needed
		}
		return (
			<div>{`Component [${props.contentType}] is missing a React Component. Check /components/Component.tsx`}</div>
		);
	}

	return <DynamicComponent {...props} />;
};
