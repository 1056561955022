import { Bento, TaglineProps } from '@almbrand/bento';
import { Countdown } from '@almbrand/countdown';
import { ctaGroupMargins, generateButtonArray } from 'utilities';

export interface BentoComponentProps extends LocalId {
	content: string;
	contentId: number | string;
	buttons?: Button[];
	ctaMarginTop?: string;
	ctaMarginBottom?: string;
	backgroundColor?: BackgroundColor;
	tagline?: string;
	taglineColor?: string;
	height: 'standard' | 'large';
	countdownTitle?: string;
	countdownEndDate?: string;
	countdownExpiredText?: string;
	theme?: 'light' | 'dark';
}

export const BentoComponent: React.FC<BentoComponentProps> = ({
	content,
	contentId,
	buttons,
	ctaMarginTop,
	ctaMarginBottom,
	backgroundColor,
	tagline,
	taglineColor,
	height,
	countdownTitle,
	countdownEndDate,
	countdownExpiredText,
	theme,
}) => {
	return (
		<Bento
			id={contentId}
			content={content}
			backgroundColor={backgroundColor?.cssClass}
			tagline={{ title: tagline, color: taglineColor as TaglineProps['color'] }}
			height={height}
			ctaGroupProps={generateButtonArray(buttons, ctaGroupMargins(ctaMarginTop, ctaMarginBottom))}
		>
			{countdownEndDate && (
				<Countdown
					title={countdownTitle}
					endDate={countdownEndDate}
					expiredText={countdownExpiredText}
					themeColor={theme}
				/>
			)}
		</Bento>
	);
};
