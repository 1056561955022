import { GridCell } from '@almbrand/grid';
import classNames from 'classnames';
import { Component } from 'components/Component';

export const GridCellComponent: React.FC<{ component: ContentApiModel; applySpacings?: boolean }> = ({
	component,
	applySpacings,
}) => {
	const { gridSize, tabletGridSize, mobileGridSize, gridMove, mobileGridMove, tabletGridMove } =
		component?.grid ?? {};

	const getGridSize = (size?: GridCellWidth | 'None', defaultSize?: GridCellWidth) => {
		return !size || size === 'None' ? defaultSize : size;
	};

	const getOffset = (offset?: GridCellWidth | 'None') => {
		return !offset || offset === 'None' ? gridMove : offset;
	};

	const { marginBottom, marginTop, gridAlignment } = component?.style ?? {};

	return (
		<GridCell
			desktopWidth={gridSize}
			tabletWidth={getGridSize(tabletGridSize, gridSize)}
			mobileWidth={getGridSize(mobileGridSize, '12col')}
			desktopOffset={gridMove}
			tabletOffset={getOffset(tabletGridMove)}
			mobileOffset={mobileGridMove}
			className={classNames(
				applySpacings && `pb-${marginBottom} pt-${marginTop}`,
				gridAlignment !== 'top' && `u-grid__cell--${gridAlignment}`,
			)}
		>
			<Component {...component?.content} />
		</GridCell>
	);
};
